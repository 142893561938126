import { PureComponent } from "react";
import EmojiResultsRow from "./EmojiResultRow";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class EmojiResults extends PureComponent {

  render() {
    const {Emojis, Caption, FavFunc, saveCB, favorates } = this.props;

//state
    const columnSize = 10;
    let leftData = [];
    let midData = [];
    let rightData = [];

    if (Emojis.length <= columnSize) {
      leftData = Emojis.slice(0, columnSize);
    } else if (Emojis.length <= 2 * columnSize) {
      leftData = Emojis.slice(0, columnSize);
      midData = Emojis.slice(columnSize, 2 * columnSize );
    } else {
      leftData = Emojis.slice(0, columnSize);
      midData = Emojis.slice(columnSize, 2 * columnSize );
      rightData = Emojis.slice(2 * columnSize);
    }

    let idx = 0;

    return (
     
      <div className="component-emoji-results">
      <Container>
      <Row>
        <Col>
        {leftData.map((emoji) => (
          <EmojiResultsRow
            key={emoji.title}
            symbol={emoji.symbol}
            title={emoji.title}
            Caption={Caption}
            FavFunc={FavFunc} 
            saveCB={saveCB}
            idx={idx++}
            favorates={favorates}
          />
        ))}
        </Col>
        <Col>
        {midData.map((emoji) => (
          <EmojiResultsRow
          key={emoji.title}
          symbol={emoji.symbol}
          title={emoji.title}
          Caption={Caption}
          FavFunc={FavFunc} 
          saveCB={saveCB}
          idx={idx++}
          favorates={favorates}
        />
        ))}
        </Col>
        <Col>
        {rightData.map((emoji) => (
          <EmojiResultsRow
          key={emoji.title}
          symbol={emoji.symbol}
          title={emoji.title}
          Caption={Caption}
          FavFunc={FavFunc} 
          saveCB={saveCB}
          idx={idx++}
          favorates={favorates}
        />
        ))}
        </Col>
      </Row>
    </Container>     
      </div>
    );
  }
}
