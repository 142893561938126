const copyToClipboard = async (setState, symbol, text, saveCB, del = false) => {
    let msg;
    try {
        const permissions = await navigator.permissions.query({name: "clipboard-write"})
        if (permissions.state === "granted" || permissions.state === "prompt") {
            await navigator.clipboard.writeText(symbol);
            saveCB(symbol, text);
            msg = del ? 'Emoji deleted from clipboard!' : 'Emoji copied to clipboard!';
        } else {
          msg = "Can't access the clipboard. Check your browser permissions.";
        }
    } catch (error) {
        msg = `Error copying to clipboard: ${error}`;
    }
    setState({option: 0, open:true, message:msg, CBO:null, CBX: null, CB:null});
};

export default copyToClipboard;