
export function FilterWord(emojis, searchText) {
  return emojis
    .filter((emoji) => 
        emoji.title.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
}
export function FilterPage(emojis, page, pageSize) {
  const start = page * pageSize;
  return emojis.slice(start, start + pageSize);
}

/* || emoji.keywords.includes(searchText)) */

