import { PureComponent } from "react";
import "./Header.css";

function GetTitle({isFav})  {
  return <span>{isFav ? 'Favorates' : 'Emojis'}</span>;
}

export default class Header extends PureComponent {
  

  render() {
    return (
      <header className="header" style={{display:'flex', justifyContent:'center'}}>
        <p>
        <GetTitle isFav={this.props.isFav}/>   
        </p>        
      </header>
    );
  }
}
