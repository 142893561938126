import './SearchBar.css';
import aboutText from './aboutText';

const Modal = ({modal, setModal}) => {
  
  return (
    <div className={`${modal ? "modal-container isOpen" : "modal-container"}`}>
      <div className="modal-content" >
        <p>{aboutText}</p>
        <button className="close-btn" onClick={()=>setModal(false)}>
          back
        </button>
      </div>
    </div>
  );
};
export default Modal;