import { PureComponent } from "react";
import "./EmojiResultRow.css";
import { BsClipboard2Plus } from "react-icons/bs";
import { MdFavoriteBorder } from "react-icons/md";
import { MdFavorite } from "react-icons/md";
import copyToClipboard from '../../clipboard';
import {PUC} from "../PopUpJK";
import { useState } from "react";

export default function EmojiResultRow (props) {
    const [p, setP] = PUC();

    const {symbol, title, Caption, FavFunc,  saveCB, idx, favorates } = props;
    const codePointHex = symbol.codePointAt(0).toString(16);
    const src = `//cdn.jsdelivr.net/emojione/assets/png/${codePointHex}.png`;

    const Favorites = (Caption) => 
      Caption === "to Favorites" ? <MdFavoriteBorder /> : <MdFavorite />;

    const shortTitle =  title.length > 24 ? title.substring(0, 24) + '...' : title; 
    const short = shortTitle.length < title.length; 
  
    return (
      <>
      <div
        className="component-emoji-result-row copy-it "
        data-clipboard-text={symbol}
        style={{backgroundColor: idx % 2 === 1 ? '#fff' : '#eee'}}
      >
        <img src={src} alt={title} />
        <span className="title" title={short ? title : ''}>{shortTitle}</span>
        <span title={Caption} className="info" onClick={() => FavFunc(symbol)}> {favorates.includes(symbol) ? <MdFavoriteBorder /> : <MdFavorite />}</span>     
        <span className="info" title={'to clipboard'} onClick={() => {copyToClipboard(setP, symbol, title, saveCB);}}> < BsClipboard2Plus /> </span>
      </div>
      </>
      
    );
  }

