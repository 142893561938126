import { PureComponent } from "react";
import Header from "./Dashboard/Header/Header.jsx";
import SearchBar from "./Dashboard/Searchbar/SearchBar.jsx";
import EmojiResults from "./Dashboard/Emojis/EmojiResults";
import {FilterWord, FilterPage} from "./Dashboard/Emojis/Filter";
import emojiList from "./Dashboard/Emojis/emojiList.json";

const elist = (sfv , fvts, emjs) => {
  if (sfv) {
    return emjs.filter(emj => fvts.includes(emj.symbol));
  } else {
    return emjs;
  }
}

export default class App extends PureComponent {
  state = {
    page: 0,
    pageSize: 30,
    searchWord: "",
    emojis: emojiList,
    favorates:[],
    showFvs: false,
    clipboard:'',
    symbolTitle: '',
  };

  handleChange = (event) => {
    this.setState({
      searchWord:event.target.value,
    });
  };

  handleChangePage = (option) => {
    const num = this.state.showFvs ? this.state.favorates.length : this.state.emojis.length;
    let newPage = this.state.page;
    if ( option === 'inc') { 
      if ((newPage + 1) * this.state.pageSize <= num )  
      newPage++;
    } else if (option === 'dec') {
      if (newPage > 0 )
        newPage--;
    } else if ( option === 'start') { 
      newPage = 0;
    } else if (option === 'end') {
        newPage = Math.floor(num / this.state.pageSize);
    }
    this.setState({
      page: newPage,
    });
  }

  showFavorates = () => {
    this.setState({ 
      page: 0,
      showFvs: ! this.state.showFvs
    });
  };

  addFavorate = (symbol) => {
    if (! this.state.favorates.includes(symbol)) {
      this.setState({ 
        favorates: [...this.state.favorates, symbol]
      });
    } 
  };

  delFavorate = (symbol) => {
    if (this.state.favorates.includes(symbol)) {
      let lessFavorates = this.state.favorates.filter(f => f !== symbol);
      this.setState({ 
        favorates: [...lessFavorates]
      });
    } 
  };
 
  saveClipboardItem = (symbol, text) => {
    this.setState({ 
      clipboard: symbol,
      symbolTitle: text
    });
  };

  render() {
    let Caption;
    let FavFunc;
    let Emojis = FilterWord(elist(this.state.showFvs, this.state.favorates, this.state.emojis), this.state.searchWord);
    const numPages = Math.ceil(Emojis.length / this.state.pageSize);
    Emojis =  FilterPage(Emojis, this.state.page, this.state.pageSize);
    
    if (this.state.showFvs) {  
      Caption = "remove from Favorites";
      FavFunc = this.delFavorate;
    } else {
      Caption = "to Favorites";
      FavFunc = this.addFavorate;
    }
    return (
      <div>
        <Header isFav={this.state.showFvs}/>    
        <
          SearchBar 
          CB={this.state.clipboard} 
          CBT={this.state.symbolTitle} 
          isFav={this.state.showFvs} 
          page={this.state.page} 
          numPages={numPages}
          handleChange={this.handleChange} 
          handleChangePage={this.handleChangePage} 
          showFavorates={this.showFavorates}
          saveCB={this.saveClipboardItem}
        />
        <br /><br />
        <
          EmojiResults 
          Emojis={Emojis} 
          Caption={Caption} 
          FavFunc = {FavFunc} 
          saveCB={this.saveClipboardItem}
          favorates={this.state.favorates}
        />
      </div>
    );
  }
}
//import { FaBeer } from 'react-icons/fa';
//<FaBeer />