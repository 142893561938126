import copyToClipboard from '../../clipboard';
import { FaRegClipboard } from "react-icons/fa";
import {PUC} from '../PopUpJK';

export default function ButtonComp ({CB, saveCB})  {
    const [p, setP] = PUC();
    return (
        <>
        <
          button 
          title={CB === '' ? 'clipboard' : 'remove from clipboard'} 
          onClick={() => copyToClipboard(setP, '', '', saveCB, true)}
        >
          <FaRegClipboard />
        </button>
          &nbsp;
        </>
    );
};
