const aboutText = 
<>
This is an organized collection of emojis. Check tool tips. <br />
1. The emojis data comes from a static file named "emojiList.json".<br />
2. 30 emojis are shown in one page in 3 columns. <br />
3. You can select your favorites and see them in the Favorites page.<br />
4. Can send an emoji to the clipboard and copy it to other document.<br />
5. Move the cursor to some emoji and choose it as a favorate or a clipboard item<br />
   by clicking a button.<br />
</>;

export default aboutText;

