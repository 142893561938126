import { PureComponent } from "react";
import "./SearchBar.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaFastForward } from "react-icons/fa";
import { FaStepForward } from "react-icons/fa";
import { FaFastBackward } from "react-icons/fa";
import { FaStepBackward } from "react-icons/fa";
import { MdFavoriteBorder } from "react-icons/md";
import { RiArrowGoBackFill } from "react-icons/ri";
import { IoIosInformationCircleOutline } from "react-icons/io";
import Modal from './Modal';
import ButtonComp from "./ButtonComp";

export default class SearchBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {modal:false,};
  }

  setModal = (val) => {this.setState({modal: val});};
  
  render() {
    const pageNumStyle = {
      width: '30px', 
      backgroundColor: '#fff', 
      border: '1px solid white', 
      textAlign:'right', 
      borderRadius:'5px'
    };
    const pagesNumStyle = {
      width: '30px', 
      backgroundColor: 'transparent', 
      color:'white',
      textAlign:'right', 
      borderRadius:'5px'
    };
    const clipboardStyle = {
      width: '30px', 
      backgroundColor: '#fff', 
      border: '1px solid white', 
      textAlign:'center', 
      borderRadius:'5px'
    };

    return (
     
<Container>
<Row>
  <Col>
    <input
      className="input-text"
      placeholder= {this.props.isFav ? "Search Favorates" : "Search Emojis"}
      onChange={(event) => this.props.handleChange(event)}
      style={{borderRadius:'10px', border: '1px solid #bbb'}}
    />
  </Col>
  
  <Col>
  <Row>
    <div style={{display: 'flex', justifyContent:'center'}}>
    <button  title={'first page'} onClick={()=>this.props.handleChangePage('start')}><FaFastBackward /></button>&nbsp;
    <button title={'prev page'} onClick={()=>this.props.handleChangePage('dec')}><FaStepBackward /></button>&nbsp;
    <span  title={'current page'} style={pageNumStyle}>{this.props.page + 1}</span>&nbsp;
    <span  title={'total number of pages'} style={pagesNumStyle}>{this.props.numPages}</span>&nbsp;
    <button title={'next page'} onClick= {()=>this.props.handleChangePage('inc')}><FaStepForward /></button>&nbsp;
    <button title={'last page'} onClick={()=>this.props.handleChangePage('end')}><FaFastForward /></button>
    </div>
  </Row>
  </Col>

  <Col>
  <div style={{display: 'flex', justifyContent:'center'}}>
  <ButtonComp CB={this.props.CB} saveCB={this.props.saveCB} />
  <span title={this.props.CBT}  style={clipboardStyle}>{this.props.CB}</span>&nbsp;
  <button 
    onClick={this.props.showFavorates}
    title={this.props.isFav ? 'back' : 'to Favorites'} 
    >{this.props.isFav ? <RiArrowGoBackFill /> : < MdFavoriteBorder />}</button>&nbsp;
  <button title='about' onClick={()=>{this.setModal(true);}}><IoIosInformationCircleOutline /></button>
  </div>
  </Col>
</Row>
<Modal modal={this.state.modal} setModal={this.setModal}/>
</Container>
    );
  }
}
